import LencoVirtualAccountEdit from './LencoVirtualAccountEdit';
import LencoVirtualAccountList from './LencoVirtualAccountList';
import LencoVirtualAccountShow from './LencoVirtualAccountShow';
import Leaderboard from '@mui/icons-material/Leaderboard';
const resource = {
  list: LencoVirtualAccountList,
  edit: LencoVirtualAccountEdit,
  show: LencoVirtualAccountShow,
  icon: Leaderboard,
};

export default resource;
