import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { EIN8822BStatus } from '../../config/constants';
const EIN8822BStatusArray = EIN8822BStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Ein8822bListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const Filters = [
  <SelectInput source="status" label="Status" choices={EIN8822BStatusArray} alwaysOn />,
];

const Ein8822bList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }} actions={<Ein8822bListActions />}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <SelectField source="status" label="Status" choices={EIN8822BStatusArray} />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default Ein8822bList;
