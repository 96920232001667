import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@emotion/styled';
import React from 'react';
import dataProvider from '../../config/dataProvider';
import { useNotify, usePermissions } from 'react-admin';

import { hasPermission } from '../../lib/SGHelper';

import { EIN8822BStatus } from '../../config/constants';
const EIN8822BStatusArray = EIN8822BStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

export interface BE12SurveyStatusProps {
  data: any;
  onSuccess?: () => void;
}
export const Ein8822bWorkflowStatus = (props: BE12SurveyStatusProps) => {
  const [status, setStatus] = useState(props.data?.status);
  const [processing, setProcessing] = useState(false);
  const id = props.data?.id;
  const companyId = props.data?.companyId;
  const notify = useNotify();
  const [upload, setUpload] = useState(false);
  const { permissions } = usePermissions();

  const isLiabilityShowable =
    hasPermission('ROLE_STATE_FILER', permissions) ||
    hasPermission('ROLE_SUPER_ADMIN', permissions) ||
    hasPermission('ROLE_EIN_SERVICE_STARTGLOBAL', permissions);

  const handleUploadSuccess = () => {
    setUpload(true);

    if (typeof props.onSuccess === 'function') {
      props.onSuccess();
    }
  };
  const handleUploadError = () => {
    setUpload(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const submit = (_data: any) => {
    setProcessing(true);
    _data = {
      ..._data,
      id: props.data?.id,
      status: status,
    };
    dataProvider
      .post(`ein_8822b_workflow/${id}/status`, { data: _data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Wrapper>
      <StatusUpdate>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={status}
            label="Age"
            onChange={handleChange}
          >
            {isLiabilityShowable
              ? EIN8822BStatusArray.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)
              : EIN8822BStatusArray.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
          </Select>
        </FormControl>
        <LoadingButton
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          onClick={() => submit(props.data)}
        >
          Update Status
        </LoadingButton>
      </StatusUpdate>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
`;

const StatusUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 50%;
`;
