import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PaymentStatusForm from './federal-tax-forms/PaymentStatusForm';
import FederalTaxStatusForm from './federal-tax-forms/FederalTaxStatusForm';
import FaxableCheckBoxForm from './federal-tax-forms/FaxableCheckBoxForm';
import BookkeepingCheckBoxForm from './federal-tax-forms/BookkeepingCheckBoxForm';
import EnableCheckBoxForm from './federal-tax-forms/EnableCheckBoxForm';
import GrossIncomeForm from './federal-tax-forms/GrossIncomeForm';
import EstimateChargeForm from './federal-tax-forms/EstimateChargeForm';
import DocumentListItem from './../../../components/DocumentListItem';
import CachedIcon from '@mui/icons-material/Cached';
import AddCardIcon from '@mui/icons-material/AddCard';
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import {
  CompanyDocumentPurposeForFederalTax,
  CompanyDocumentPurposeForStrategCubeFederalTax,
} from '../../../config/constants';
import TaxDueStatusForm from './federal-tax-forms/TaxDueStatusForm';
import TaxDueForm from './federal-tax-forms/TaxDueForm';
import DueDateForm from './federal-tax-forms/DueDateForm';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { useMemo } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import last from 'lodash.last';
import ZeroTransactionCheckBoxForm from './federal-tax-forms/ZeroTransactionCheckBoxForm';
import ConfirmBooksCheckBoxForm from './federal-tax-forms/ConfirmBooksCheckBoxForm';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { hasPermission } from '../../../lib/SGHelper';
import FinalTaxCheckBoxForm from './federal-tax-forms/FinalTaxCheckBoxForm';
import NotesForm from './federal-tax-forms/NotesForm';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FederalTaxMaster = (props) => {
  console.log('federal tax', props.data);
  const [value, setValue] = React.useState(0);
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_0988', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_1', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_2', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_3', permissions || props.permissions);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const initiateFederalTax = (year) => {
    const url = `update/company/${props.data.id}/federal-tax/${year}/initiate`;
    dataProvider
      .post(url, {})
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
        notify('Initiated successfully', 'success');
        setReload(!reload);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      });
  };
  React.useEffect(() => {
    setIsloading(true);
    const url = `federal-tax/company/${props.data.id}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setData(data.reverse());
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }
  if (data.length <= 0) {
    return (
      <>
        <div>No data found</div>
        <hr />
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() => {
            initiateFederalTax(new Date().getFullYear() - 1);
          }}
        >
          {' '}
          Initiate Now{' '}
        </Button>
      </>
    );
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} style={{ width: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
            style={{ width: '100%' }}
          >
            {data?.map((d, index) => (
              <StyledTab
                label={d.year}
                {...a11yProps(index)}
                style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
              />
            ))}
          </Tabs>
        </Grid>
        {/* <Grid item xs={12} sm={10} md={10}>
                        {
                            data?.map( (d, index) => (
                                <TabPanel value={value} index={index}>
                                    <FederalTaxDetail data={d} />
                                </TabPanel>)
                                )
                        }
                </Grid> */}
        <Grid item xs={12} sm={10} md={10}>
          {data?.map((d, index) => (
            <TabPanel value={value} index={index}>
              <FederalTaxDetail
                year={d.year}
                data={d}
                subTab={props.subTab}
                company={props.data.company}
                permissions={props.permissions}
              />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Yearwise detail
 */
const FederalTaxDetail = (props) => {
  const getTabValue = (tab) => {
    switch (tab) {
      case 'chats':
        return 2;
      default:
        return 0;
    }
  };
  // const [value, setValue] = React.useState(0);
  const [value, setValue] = React.useState(props?.subTab ? getTabValue(props?.subTab) : 0);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Documents" {...a11yProps(1)} />
              <Tab label="Chats" {...a11yProps(2)} />
            </Tabs>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <TabPanel value={value} index={0}>
              {' '}
              <FederalTaxMasterDetail data={props.data} permissions={props.permissions} />{' '}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {' '}
              <FederalTaxDocuments data={props.data} year={props.year} />{' '}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {' '}
              <FederalTaxChats data={props.data} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const FederalTaxMasterDetail = (props) => {
  const { permissions } = usePermissions();
  const [loading, setIsloading] = React.useState(false);
  const [revisions, setRevisions] = React.useState([]);
  const [dissolution, setDissolution] = React.useState({});
  const [notes, setNotes] = React.useState([]);

  const [reload, setReload] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_0988', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_1', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_2', permissions || props.permissions) ||
    hasPermission('ROLE_CPA_3', permissions || props.permissions);

  React.useEffect(() => {
    setIsloading(true);
    const url = `federal_tax_document_revisions/federal-tax/${props.data.id}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax-revisions', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setRevisions(data.reverse());
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  React.useEffect(() => {
    if (!props?.data?.isFinalFiling) {
      return;
    }
    const url = `company_dissolution_master/company/${props.data.companyId}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('company_dissolution_master', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setDissolution(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  }, []);
  React.useEffect(() => {
    const url = `federal-tax/company/${props.data.companyId}/federal-tax/${props.data.id}/notes`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('notes', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setNotes(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  }, []);

  if (loading) {
    return null;
  }

  const getCPAName = (cpaId) => {
    let cpaName = null;
    if (cpaId == 1) {
      cpaName = 'Sam';
    } else if (cpaId == 2) {
      cpaName = 'Sreejtih (Zero Trans)';
    } else if (cpaId == 3) {
      cpaName = 'Sreejith';
    }
    return cpaName;
  };
  const cpaName = getCPAName(props?.data?.cpaId);

  return (
    <>
      {revisions.length > 0
        ? revisions.map((r) => (
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={6} style={{ width: '100%' }}>
                  <Alert severity="error">
                    <AlertTitle>Revision Request</AlertTitle>
                    {r.remarks}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          ))
        : null}
      {dissolution?.notes?.length > 0 ? (
        <Box sx={{ marginBottom: '8px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ width: '100%' }}>
              <Alert severity="error">
                <AlertTitle>Final Tax Notes</AlertTitle>
                <pre>{dissolution?.notes}</pre>
              </Alert>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {notes?.length > 0
        ? notes.map((n) => (
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={6} style={{ width: '100%' }}>
                  <Alert severity="error">
                    <AlertTitle>Notes From Support Team</AlertTitle>
                    <pre>{n?.notes}</pre>
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          ))
        : null}

      <GrossIncomeForm data={props.data} />
      {!isCPA && <EstimateChargeForm data={props.data} />}
      <PaymentStatusForm data={props.data} disabled={isCPA} />
      <FederalTaxStatusForm data={props.data} />
      {!isCPA && (
        <>
          <TaxDueForm data={props.data} />
          <TaxDueStatusForm data={props.data} />{' '}
        </>
      )}
      <DueDateForm data={props.data} disabled={isCPA} />
      <FaxableCheckBoxForm data={props.data} />
      {false && <BookkeepingCheckBoxForm data={props.data} />}
      {false && <EnableCheckBoxForm data={props.data} />}
      <ZeroTransactionCheckBoxForm data={props.data} disabled={isCPA} />
      <FinalTaxCheckBoxForm data={props.data} disabled={isCPA} />
      {!isCPA && <NotesForm data={props.data} />}

      {false && <ConfirmBooksCheckBoxForm data={props.data} />}
      {!isCPA && (
        <Grid item xs={6} style={{ width: '100%' }}>
          {cpaName ? (
            <Alert severity="success">
              <AlertTitle>{cpaName}</AlertTitle>
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>UnAssigned</AlertTitle>
            </Alert>
          )}
        </Grid>
      )}
    </>
  );
};
const FederalTaxDocuments = (props) => {
  console.log('federal tax', props.data);
  const [value, setValue] = React.useState(0);
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [books, setBooks] = React.useState([]);

  const [reload, setReload] = React.useState(false);
  const [showAddDocument, setShowAddDocument] = React.useState(false);
  const [selectedPurpose, setSelectedPurpose] = React.useState(null);

  const { permissions } = usePermissions();

  const isStrategcube = hasPermission('ROLE_CPA_3', permissions);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const uploadOptions = isStrategcube
    ? CompanyDocumentPurposeForStrategCubeFederalTax
    : CompanyDocumentPurposeForFederalTax;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUpload = ({ target }) => {
    const purpose = selectedPurpose;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', 'error');
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload(`company-documents/upload/${props.year}`, {
        data: {
          files: acceptedFiles[0],
          companyId: props.data.companyId,
          purpose: purpose,
          keyId: props.data.id,
        },
      })
      .then((data) => {
        notify('File uploaded successfully', 'success');
        setReload((reload) => !reload);
        setShowAddDocument(false);
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  React.useEffect(() => {
    setIsloading(true);
    const url = `company_bookkeeping_master/company/${props.data.companyId}/books/${props.year}/documents`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax-documents', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setBooks(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  React.useEffect(() => {
    setIsloading(true);
    const url = `federal-tax/company/${props.data.companyId}/federal-tax/${props.data.id}/documents`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax-documents', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={7} />
          <Grid item xs={3} sm={3} md={3}>
            <IconButton size="large" color="primary" onClick={() => setShowAddDocument(true)}>
              <AddCardIcon fontSize="large" />
            </IconButton>
            <IconButton size="large" color="primary" onClick={() => setReload((reload) => !reload)}>
              <CachedIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={10} sm={10} md={10}>
            <Dialog fullWidth open={showAddDocument} onClose={() => setShowAddDocument(false)}>
              <DialogTitle>Add New Document</DialogTitle>
              <DialogContent style={{ minHeight: 400 }}>
                <Select
                  name="purpose"
                  options={uploadOptions.map((c) => {
                    return { value: c.id, label: c.label };
                  })}
                  onChange={(selectedOption) => setSelectedPurpose(selectedOption?.value)}
                />

                <input
                  accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id={'s'}
                  style={{ display: 'none' }}
                  onChange={handleUpload}
                  type="file"
                  disabled={!!!selectedPurpose}
                />
                <label htmlFor={'s'}>
                  <Tooltip title="Upload">
                    <IconButton
                      edge="end"
                      aria-label="upload"
                      component="span"
                      disabled={!!!selectedPurpose}
                    >
                      <UploadIcon className="documentIcons" />
                    </IconButton>
                  </Tooltip>
                </label>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowAddDocument(false)}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <List>
              {books.length
                ? books.map((d) => (
                    <DocumentListItem
                      readOnly
                      id={d.id}
                      companyId={d.companyId}
                      purpose={d.purpose}
                      text={d.purpose}
                      uuid={d.uuid}
                      exists
                    />
                  ))
                : null}
              {data.length
                ? data.map((d) => (
                    <DocumentListItem
                      id={d.id}
                      companyId={d.companyId}
                      purpose={d.purpose}
                      text={d.purpose}
                      uuid={d.uuid}
                      exists
                    />
                  ))
                : null}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
const FederalTaxChats = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [messageId, setMessageId] = React.useState(0);
  const [currentMessage, setCurrentMessage] = React.useState('');
  React.useEffect(() => {
    setIsloading(true);
    const url = `chat_master/${props.data.companyId}/owner/TAX_EXPERT`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        const _ms = data.map((m) => {
          const myMessage = m.sender === 'CLIENT';
          const chatId = m.id;
          return {
            id: chatId,
            content: {
              type: 'text',
              body: m.message,
            },
            date: m.createdDate,
            sender: myMessage,
          };
        });
        setData(_ms);
        if (_ms.length > 0) {
          setMessageId(_ms[0].id);
        }
        setMessages(_ms.reverse());
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  const addChat = (msg) => {
    let messages_ = messages;

    setMessages([
      ...messages,
      {
        content: {
          type: 'text',
          body: msg,
        },
        sender: false,
      },
    ]);
  };
  const onChangeOfInputMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const onKeyPressOfInputMessage = (e) => {
    if (e.key == 'Enter') {
      if (!currentMessage) {
        return;
      }
      saveChatMessage(currentMessage);
      addChat(currentMessage);
      setCurrentMessage('');
    }
  };

  const saveChatMessage = (msg) => {
    if (!msg) {
      return;
    }
    const data = {
      message: msg,
      companyId: Number(props.data.companyId),
      sendFrom: 'TAX_EXPERT',
    };

    dataProvider
      .post(`chat_master/reply/${messageId}`, { data })
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  };

  return (
    <ChatWrapper>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '50vh',
        }}
      >
        <MessageList messages={messages} />
      </div>
      <div
        style={{
          padding: '16px 16px',
          display: 'flex',
          border: '1px solid #EEEEEE',
          borderRadius: '4px',
          width: '-webkit-fill-available',
          display: 'flex',
          gap: '1em',
        }}
      >
        <TextField
          id="filled-basic"
          label=""
          variant="filled"
          placeholder="Type here!"
          fullWidth
          value={currentMessage}
          onChange={onChangeOfInputMessage}
          onKeyPress={onKeyPressOfInputMessage}
        />
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() => {
            saveChatMessage(currentMessage);
            if (currentMessage) {
              addChat(currentMessage);
              setCurrentMessage('');
            }
          }}
        >
          Send
        </Button>
      </div>
    </ChatWrapper>
  );
};

const ChatWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content:space-between'
background: white;
border: 1px solid #EEEEEE;
border-radius: 4px;
over-flow:auto;
width:70%;
`;

const MessageList = (props) => {
  const senderGroupedMessages = useMemo(
    () =>
      props.messages.reduce((arr, m) => {
        const sender = m.sender;
        if (!last(arr)) {
          arr.push({
            messages: [m],
            sender,
          });
        } else if (last(arr)?.sender === sender) {
          last(arr)?.messages.push(m);
        } else {
          arr.push({
            messages: [m],
            sender,
          });
        }
        return arr;
      }, []),
    [props.messages]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      {senderGroupedMessages.map((mg) => (
        <MessageGroup key={mg.messages[0].id} {...mg} />
      ))}
    </div>
  );
};

const MessageGroup = (props) => {
  return (
    <MessageGroupWrapper>
      {props.sender ? (
        <div className="pt-2 pr-2">
          <AccountCircleIcon />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8em',
          alignItems: !props.sender ? 'flex-end' : 'flex-start',
          width: '100%',
        }}
      >
        {props.messages.map((m, i) => (
          <Message key={m.id} {...m} last={i === props.messages.length - 1} />
        ))}
      </div>
    </MessageGroupWrapper>
  );
};

const MessageGroupWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-start;
`;

const Message = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.last && !props.sender ? 'flex-end' : 'unset',
    }}
  >
    <div
      style={{
        maxWidth: '30rem',
        width: 'fit-content',
        border: '1px solid #EEEEEE',
        padding: '10px 16px',
        background: !props.sender ? '#d4eeda' : '#ffdada',
        borderRadius: '4px',
      }}
    >
      {props.content.type === 'text' ? <span>{props.content.body}</span> : null}
    </div>
    {props.last ? (
      <div
        style={{
          textAlign: !props.sender ? 'end' : 'start',
          marginTop: '4px',
          fontSize: '10px',
          color: '#929398',
        }}
      >
        {props.date}
      </div>
    ) : null}
  </div>
);

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

export default FederalTaxMaster;
