import EmployeeIcon from '@mui/icons-material/People';

import Ein8822bList from './Ein8822bList';
import Ein8822bView from './Ein8822bView';
const resource = {
  list: Ein8822bList,
  show: Ein8822bView,
  icon: EmployeeIcon,
};

export default resource;
