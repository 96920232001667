import List from '../../components/List';
import { AutocompleteInput, Datagrid, EditButton } from 'react-admin';
import TextField from '../../components/TextField';
import ReferenceField from '../../components/ReferenceField';
import NumberField from '../../components/NumberField';
import DateField from '../../components/DateField';
import TextInput from '../../components/TextInput';
import NumberInput from '../../components/NumberInput';
import SelectInput from '../../components/SelectInput';
import SelectField from '../../components/SelectField';
import { PaymentStatus } from '../../config/constants';
const PaymentStatusArray = PaymentStatus.map((f) => ({
  id: f.id,
  name: f.name,
}));
const Filters = [
  <TextInput source="companyId" label="Company" />,
  <SelectInput
    source="Payment Status"
    choices={[
      { id: 'PAID', name: 'PAID' },
      { id: 'NOT_PAID', name: 'NOT_PAID' },
    ]}
    alwaysOn
  />,
];

const LencoVirtualAccountList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField source="companyId" reference="company-master-data">
          <TextField source="id" />
        </ReferenceField>
        <TextField source="accountName" label="Account Name" />
        <TextField source="accountNumber" label="AccountNumber" />
        <TextField source="currency" label="Currency" />
        <NumberField source="amount" label="Amount" />
        <TextField source="bankCode" label="BankCode" />
        <TextField source="bankName" label="BankName" />
        <TextField source="exchangeRate" label="ExchangeRate" />
        <DateField source="expiresAt" label="Expires At" />
        <TextField source="status" label="Status" />
        <SelectField source="paymentStatus" label="Payment Status" choices={PaymentStatusArray} />
        <TextField source="type" label="Type" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default LencoVirtualAccountList;
