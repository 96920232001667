import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useShowController,
  FunctionField,
  Labeled,
  useNotify,
  useDataProvider,
  usePermissions,
} from 'react-admin';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderList } from '../../components/ListHeaderActions';
import { Box, FormHelperText, Typography } from '@mui/material';
import { hasPermission } from '../../lib/SGHelper';
import dataProvider from '../../config/dataProvider';
import { Ein8822bWorkflowStatus } from './Ein8822bWorkflowStatus';

const Ein8822bView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderList />}>
      <MyShow {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default Ein8822bView;

const MyShow = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const ein8822b = record;

  const { permissions } = usePermissions();

  const isLiabilityShowable =
    hasPermission('ROLE_STATE_FILER', permissions) ||
    hasPermission('ROLE_SUPER_ADMIN', permissions) ||
    hasPermission('ROLE_EIN_SERVICE_STARTGLOBAL', permissions);
  const [reloadDocuments, setReloadDocuments] = React.useState(false);
  const [filings, setFilings] = React.useState(record?.be12filings);

  console.log({ record });

  if (!record?.id) {
    return null;
  }

  return (
    <TabbedShowLayout>
      <Tab label="Ein8822b Workflow">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <SimpleShowLayout> */}
            <EmployeeDetails>
              <LabelField
                record={ein8822b}
                source="id"
                label="Ref."
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={ein8822b}
                source="companyId"
                label="Company Ref."
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
            </EmployeeDetails>

            <Spacer />
          </Grid>

          <Grid item xs={12}>
            {ein8822b?.id && <Ein8822bWorkflowStatus data={ein8822b} />}
          </Grid>
          <Spacer />
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;
